.accordionPanel .accordion_container {
  // margin: 1rem;
  max-width: 960px;
  margin: auto;
}
.accordionPanel .wrapper {
  display: flex;
  width: 100%;
}
.accordionPanel .accordion {
  width: 100%;
}
.accordionPanel .item {
  //   background: rgb(250, 250, 250);
  //   margin-bottom: 5px;
  //   border-radius: 10px;
  border-top: 1px solid #c2a56b;
}
.accordionPanel .title {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 24px;
  position: relative;
  @media screen and (max-width: 576px) {
    padding: 15px 55px 15px 0;
  }
}
.accordionPanel .title h4 {
  margin: 0;
}
.accordionPanel .content {
  //   color: rgb(100, 100, 100);
  height: 0;
  overflow: hidden;
  transition: 0.2s all ease;
  box-sizing: content-box !important;
  padding: 0 24px;
}
.accordionPanel .content.show {
  height: auto;
  padding: 0 24px 24px 24px;
  @media screen and (max-width: 576px) {
    padding: 0 24px 24px 0;
  }
}
.accordionPanel .open_button {
  position: absolute;
  height: 16px;
  width: 16px;
  top: 50%;
  right: 24px;
  margin-top: -8px;
  //   opacity: 0.3;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  background: transparent;
  outline: none;
}
.accordionPanel .open_button:before,
.accordionPanel .open_button:after {
  // width: 16px;
  // height: 2px;
  top: 7px;
  content: "";
  display: block;
  position: absolute;
  transition: 0.3s;
  border-radius: 4px;
  background: currentColor;
}
.accordionPanel .open_button:after {
  transform: rotate(90deg);
}
.accordionPanel .title.show .open_button {
  transform: rotate(180deg);
}
.accordionPanel .item:hover .open_button {
  opacity: 0.7;
}
.accordionPanel .accordion .content_text {
  position: relative;
  top: -10px;
  opacity: 0;
  transition: top 0.3s ease 0.3s, opacity 0.3s ease 0.3s;
}
.accordionPanel .accordion.show .content_text {
  opacity: 1;
  top: 0;
  transition: top 0.3s ease 0.2s, opacity 0.3s ease 0.2s;
}
.accordion_container .wrapper:last-of-type .item {
  border-bottom: 1px solid #c2a56b;
}
