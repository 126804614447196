.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;

  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
    }
  }
}

.pageTile__content {
  &:hover,
  &:focus {
    text-decoration: none;
    .tile__bg {
      transform: scale(1.02);
    }
    .pageTile__title {
      color: #c2a56b;
    }
    .pageTile__button {
      color: #010014;
      background: #fff;
      text-decoration: none;
    }
  }
}

.pageTile__title {
  font-family: $family-header;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 0.5rem 0;
  color: #eace96;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 0.8rem;
  color: #fff;
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  text-align: left;
  background-color: $primary-color;
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(
      to bottom left,
      rgba(#fff, 0.3),
      rgba(#fff, 0)
    );
  }
  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}

.pageTile__blur {
  width: 100%;
  height: 100%;
  background: rgba(17, 25, 33, 0.8);
  backdrop-filter: blur(10px);
  padding: 1.3rem;
}
.pageTile__bsyc__title {
  font-family: $family-header;
}
.pageTile__button {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  padding: 0.2rem 1rem;
  border-radius: 0.25rem;
  display: inline-block;
  margin-top: 1.2rem;
  font-size: 0.7rem;
  font-weight: bold;
}

.pageTile__bsyc__content__details {
  @media screen and (max-width: 576px) {
    order: 2;
  }
}
.pageTile__bsyc__blur {
  width: 100%;
  height: 100%;
  background: rgba(17, 25, 33, 0.8);
  backdrop-filter: blur(10px);
}
.pageTile__bsyc__button {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  padding: 0.2rem 1rem;
  border-radius: 0.25rem;
  display: inline-block;
  margin-top: 1.2rem;
  font-size: 0.7rem;
  font-weight: bold;
}
