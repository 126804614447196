@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

//@import './fonts';
@import "./base";
@import "./sections";
@import "./form";

// top
//@import './layout/hamburger';
//@import './layout/top';
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";
@import "./modules/accordingPanel";

// components
@import "../components";

@import "./site/index";

//aos
@import "~aos/src/sass/aos";

@media print {
  @import "./print";
}

@import "./youtubeNew.scss";

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }
  div.file__attach__text {
    margin-left: 1rem;
    //color: white;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

a.button.secondary {
  color: #454345;
  border: 1px solid #454345;
  background: transparent;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  border-radius: 0.25rem;
  &:focus,
  &:hover {
    color: #fff;
    background: #454345;
    text-decoration: none;
  }
}
a.button.third {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  border-radius: 0.25rem;
  &:focus,
  &:hover {
    color: #010014;
    background: #fff;
    text-decoration: none;
  }
}

// review-panel
#review-panel {
  background-repeat: no-repeat;
  background-size: cover;
}
#review-panel .container,
#review-panel .htmlPanel {
  max-width: 1200px;
}
.rotatingReviewItems .slick-slide {
  margin-right: 20px;
  margin-left: 20px;
  background: rgba(17, 25, 33, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 50px;
}
#review-panel .rotatingReviewItems .slick-prev::before,
#review-panel .rotatingReviewItems .slick-next::before {
  color: white;
  padding: 0;
}
#review-panel .htmlPanel p {
  max-width: 840px;
  margin: 0;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 50px;
  @media (min-width: 576px) {
    margin-bottom: 75px;
  }
}
.rotatingReviewItems .starsImage {
  width: 40%;
}
