$breakpoints: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
  xxl: 3,
);
$margin: 30px;

.blogTiles {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display: flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;

  margin-top: 2rem;
  margin-bottom: 2rem;

  &__loadMore {
    margin-bottom: 2rem;
    .thinBtn {
      color: #fff;
      border: 1px solid #fff;
      background: transparent;
      padding: 0.2rem 1rem;
      border-radius: 0.25rem;
      display: inline-block;
      margin-top: 1.2rem;
      font-size: 0.7rem;
      font-weight: bold;
      cursor: pointer;
      &:hover,
      &:focus {
        color: #010014;
        background: #fff;
        text-decoration: none;
      }
    }
  }

  &--productView .blogTile {
    @include media-breakpoint-up(xxl) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
}

.blogTile {
  padding: $margin/2;

  @each $bp, $number in $breakpoints {
    @include media-breakpoint-up(#{$bp}) {
      flex: 0 0 100% / $number;
      max-width: 100% / $number;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    color: black;
    box-shadow: 1px 1px 5px 0px #e0e0e0;
    line-height: 1.1;
    text-align: left;
    &:hover,
    &:focus {
      text-decoration: none;
      .thinBtn {
        color: #010014;
        background: #fff;
      }
      .blogTile__bg_img {
        transform: scale(1.05);
        transition: all 0.2s ease;
      }
    }
  }
  &__bg {
    overflow: hidden;
    width: 100%;
    z-index: -1;
  }

  &__bg_img {
    @include bg-img();
    @include img-ratio(100%);
    width: 100%;
    transition: all 0.2s ease;
  }

  &__content {
    width: 100%;
  }

  &__contentInner {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }

  &__title {
    // font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.2;
    margin: 0.9rem 0.2rem;
    text-align: center;
    color: #eace96;
  }

  &__desc {
    font-size: 0.8rem;
    font-style: italic;
    height: 1.5rem;
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: none;
  }
}

.thinBtn {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  padding: 0.3rem 1rem;
  border-radius: 0.25rem;
  display: inline-block;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  &:hover,
  &:focus {
    color: #010014;
    background: #fff;
    text-decoration: none;
  }
}

.blogTile__blur {
  background: rgba(17, 25, 33, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 8px;
  height: 100%;
}
