// overriding bootstrap variables in _boostrap-override.scss
@import url("https://use.typekit.net/hpk4ytx.css");

$family-base: "proxima-nova", sans-serif;
$family-header: "freight-text-pro", serif;

// font-size
$font-size-desktop: 18px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-normal: $weight-light;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #007bff;
$color-red: #c20f2f;
$color-orange: #fd7e14;
$color-green: #28a745;

// more colors
$color-gold: #c2a56b;
$color-gray: #454345;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-gold;
$font-color: $color-gray;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #ebebeb;
$link-color: $primary-color;
$link-hover-color: #7f6532;

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (
  mobile: 44px,
  small-device: 82px,
  tablet: 82px,
  desktop: 82px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
